
import {ref, watch} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import useAlert from "@/composables/Alert.ts";
import {hideModal} from '@/core/helpers/dom';
import {servicoDelete} from "@/services/CrudService";
import moment from "moment";
import useEmitter from "@/composables/Emmiter";

export default {
  name: "deletarServicoModal",

  props: {
    servicos: {
      type: Object,
      required: true,
    }
  },

  emits: ["atualizar"],

  setup(props, { emit }) {
    const logs: any = ref({});
    const emitter = useEmitter();

    let animate = ref(true);

    const {showTimeAlert} = useAlert();

    const dataFormatada: any = ref("");

    watch(() => props.servicos, () => {
      logs.value = props.servicos
      dataFormatada.value = logs.value && logs.value.dataAlteracao ? moment(logs.value.dataAlteracao, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : '';
    });

    async function deletServico(codServico) {
      animate.value = true
      await servicoDelete(codServico);
      emit("atualizar");
      animate.value = false
    }

    function deletarServico() {
      animate.value = true

      if (props.servicos) {
        Swal.fire({
          title: "Deseja excluir esse serviço?",
          text: "Essa alteração não poderá ser desfeita!",
          showCancelButton: true,
          confirmButtonText: "Excluir serviço",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-flex background_btn_1_opct text-white",
            cancelButton: "btn btn-light me-3",
          },
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            deletServico(props.servicos?.codServico);
            const elementModal = document.getElementById("deletarServicoModal");
            hideModal(elementModal);
            showTimeAlert("Serviço excluída com sucesso");
          }
        });
        animate.value = false
      }
    }

    return {
      logs,
      animate,
      deletarServico,
      dataFormatada
    }
  }
}
