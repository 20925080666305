
import { defineComponent, reactive, toRefs, ref , onMounted , onUnmounted  } from "vue";
import { ContentLoader } from "vue-content-loader";
import { servicosList } from "@/services/CrudService";
import { useRouter } from "vue-router";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import useEmitter from "@/composables/Emmiter";
import DeletarServicoModal from "@/views/crud/servicos/components/DeletarServicoModal.vue";
import VisualizarServicoModal from "@/views/crud/servicos/components/VisualizarServicoModal.vue";
import CopiarServicoModal from "@/views/crud/servicos/components/CopiarServicoModal.vue";
import TableServices from "./components/TableServices.vue"
import ImportServicos from "./components/ImportServicos.vue";
import XLSX from "xlsx-js-style";
import { useStore } from "vuex";
import moment from "moment";

export default defineComponent({
  name: "servico-list",

  components: {
    VisualizarServicoModal,
    DeletarServicoModal,
    ContentLoader,
    CopiarServicoModal,
    ImportServicos,
    TableServices
  },

  setup() {
    const store = useStore();
    const emitter = useEmitter();
    const codConcessionaria = ref();
    codConcessionaria.value = window.localStorage.getItem('filtroConcessionaria')
    const activeName = ref('first')
    const servicosDesabilitado : any =  ref({})
    const servicos: any = ref([])
    const inputDisableOrEnable = ref(true)
    const activeTab = ref(true)
    const fastFilter: any = ref("");
    const loadingTable = ref(true);
    const servicosHabilidados : any = ref({});
    const animate = ref(true);
    const postData = ref({})
    const router = useRouter();
    const servicoEspecifico: any = ref({});
    let blob: any = ref();
    const delayTimer = ref();
    
    const data = reactive({
      codServico: "",
      codConcessionaria: "",
      codCategoria: "",
      nome: "",
      tipo: "",
      valorCompra: "",
      valorPeca: "",
      valorMaoDeObra: "",
      valorTotal: "",
      valorPremiacao: "",
      tempoMaoObra: "",
      ordemExibicao: "",
      corColuna: "",
      situacao: "",
      intServico: "",
      intServicoMO: "",
      codwynns: "",
      unidadescaixa: "",
      estoque: "",
      dataestoque: "",
      fornecedor: "",
      ticketmedio: "",
      excluido: "",
      usuarioexcluiu: "",
      codPersonalizacao: "",
      intGrupo: "",
      intSubGrupo: "",
      ultNfFaturada: "",
      ultNfDtFaturada: "",
      dataCadastro: "",
      dataAlteracao: "",
      current_page: 1,
      total: 0,
      to: 0,
      last_page: 0,
      per_page: 50,
      servicoHabilitados: [],
      servicoDesabilitados: [],
    });  
    
    emitter.on("filtrar-crud-servicos", (data) => {
      codConcessionaria.value = data.codConcessionaria;

      postData.value = {
        nomeServico: data.filtrosServicos.nomeServico,
        situacaoServico: data.filtrosServicos.situacaoServico,
        codigoPecaServico: data.filtrosServicos.codigoPecaServico,
        codigoMoServico: data.filtrosServicos.codigoMoServico,
      };

      listaServicos();
    });    

    onMounted(() => {
      listaServicos()
    })

    async function listaServicos() {
      animate.value = true;
      loadingTable.value = true;
     
      servicos.value =  await servicosList(codConcessionaria.value, postData.value); 

      servicos.value = servicos.value.map((servico) => {
        return {
          ...servico,
          estoque: servico.estoque ?? 0,
        };
      });      

      servicosHabilidados.value = servicos.value.filter(servico =>  servico.situacao == 1)     
      servicosHabilidados.value = servicosHabilidados.value.map((servico) => {
        return{
          ...servico,
          arrayCodigoIntServico : servico.intServico?.split('/'),
          arrayCodigoMo : servico.intServicoMO?.split('/')
        }
      })
      servicosDesabilitado.value = servicos.value.filter(servico =>  servico.situacao == 0)
      servicosDesabilitado.value = servicosDesabilitado.value.map((servico) => {
        return{
          ...servico,
          arrayCodigoIntServico : servico.intServico?.split('/'),
          arrayCodigoMo : servico.intServicoMO?.split('/')
        }
      })

      fastFilter.value = "";      
      trataInfoHabilitados()
      tradaInfoDesabilidado()

      trataInfo()

      loadingTable.value = false;
      animate.value = false;
    }
    
    function sortArray(array){
      array.sort((a, b) => {
        if (a.fornecedor !== b.fornecedor) {
          return a.fornecedor - b.fornecedor;
        } else if (a.tipo !== b.tipo) {
          return a.tipo - b.tipo;
        } else {
          return a.codCategoria - b.codCategoria;
        }
      });
    }

    function trataInfo(page = 1, filter = false, servicos = servicosHabilidados.value , dataKey = 'servicoHabilitados') {
      let baseDadosServicos = [];

      if (filter && fastFilter.value) {
        baseDadosServicos = servicos.filter((servico) =>
          servico.nome.toLowerCase().includes(fastFilter.value.toLowerCase())
        );
      } else {
        baseDadosServicos = servicos;
      }

      data.total = baseDadosServicos.length;
      data.last_page = Math.ceil(baseDadosServicos.length / data.per_page) ?? 0;

      data.current_page = page;
      const start = data.per_page * (page - 1);
      const end = data.per_page * page;

      if (data.last_page == page) {
        data.to = baseDadosServicos.length;
      } else if (end > baseDadosServicos.length) {
        data.to = baseDadosServicos.length;
      } else {
        data.to = end;
      }

      data[dataKey] = baseDadosServicos.slice(start, end);

      // Subir para o topo da página
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }

    function trataInfoHabilitados(page = 1, filter = false) {      
      sortArray(servicosHabilidados.value)
      trataInfo(page, filter, servicosHabilidados.value, 'servicoHabilitados')
    }  

    function tradaInfoDesabilidado(page = 1, filter = false) {      
      sortArray(servicosDesabilitado.value)   
      trataInfo(page, filter, servicosDesabilitado.value, 'servicoDesabilitados');
    }

    const handleClickTab = (div) => {
      
      fastFilter.value = "";
      inputDisableOrEnable.value = !inputDisableOrEnable.value

      if(div === 'habilitados') {
        activeTab.value = true
        trataInfoHabilitados();       
      }

      if(div === 'desabilitados') {
        activeTab.value = false
        tradaInfoDesabilidado();
      }     
    }

    const classFornecedor =  (fornecedor) => {
      const fornecedorClasses = {
        1: 'distribuidor',
        2: 'outros',
        3: 'originais',
      };

      return fornecedorClasses[fornecedor] || '';
    }

    function goTo(url) {
      router.push(url);
    }

    function filterDataHabilitado(tab) {
      clearTimeout(delayTimer.value);
      delayTimer.value = setTimeout(function () {
        if(tab === 'habilitados'){
          trataInfoHabilitados(1, true);
        }

        if(tab === 'desabilitados'){
          tradaInfoDesabilidado(1, true);
        }
      }, 300);
    }

    function setServicoEspecifico(servico) {
      servicoEspecifico.value = servico;
    }

    function goToParams(routeName, servico) {
      router.push({
        name: routeName,
        params: {
          codServico: servico.codServico,
        },
      });
    }

    const listener = () => {
      gerarXLSX();
      exportarXLSX();
    };

     const reloadServico = () => {
      listaServicos()
    }

    function downloadExel(){
      listener()
    }    

    emitter.on("geraServicoXLSX", listener);
    emitter.on("importExcel", reloadServico);

    onUnmounted(() => {
      emitter.off("geraServicoXLSX", listener);
      emitter.off("importExcel", reloadServico);     
    });


    let distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
    let fornecedor = `Fornecedor (1 = ${distribuidor} / 2 = Outros / 3 = Originais)`;

    function gerarXLSX() {

      const servicosToXLSX = [...servicosHabilidados.value].map((item) => {
        return {
          "Serviço": item.nome,
          "Código Categoria (1 = Agregados / 2 = Alinhamento / 3 = Pneus / 4 = Baterias / 5 = Palhetas / 6 = Acessórios / 7 = Estética / 8 = Funilaria / 9 = Balanceamento)": item.codCategoria,
          "Código Imagem/Video/Descricao": item.codPersonalizacao,
          "Tipo (2 = Produtos / 3 = Serviços)": item.tipo,
          [fornecedor]: parseInt(item.fornecedor),
          "Contabiliza Ticket Médio (1 = Sim / 2 = Não)": item.ticketmedio,
          "Situação (1 = Habilitado / 0 = Desabilitado)": item.situacao,
          "Valor Compra": item.valorCompra ? parseFloat(item.valorCompra.replace(",", ".")) : 0.00,
          "Valor Peça": item.valorPeca ? parseFloat(item.valorPeca.replace(",", ".")) : 0.00,
          "Valor Mão de Obra": item.valorMaoDeObra ? parseFloat(item.valorMaoDeObra.replace(",", ".")) : 0.00,
          "valor Hora Mão de Obra" : item.valorHoraMO ? parseFloat(item.valorHoraMO.replace(",", ".")) : 0.00,
          "Tempo Mão de Obra": parseFloat(item.tempoMaoObra),
          "Valor Total": item.valorTotal ? parseFloat(item.valorTotal.replace(/\./g, "").replace(",", ".")) : 0.00,
          "Valor Premiação": item.valorPremiacao ? parseFloat(item.valorPremiacao.replace(",", ".")) : 0.00,
          "Código Peça": item.intServico,
          "Código MO": item.intServicoMO,
          "Código Forrnecedor": item.codwynns,
          "Unidades por Caixa": item.unidadescaixa ?? 0.00,
          "Estoque Atual": item.estoque ?? 0.00,
          "Data Estoque": item.dataestoque 
            ? moment(item.dataestoque, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY') 
            : null
        };
      });

      let wb: XLSX.WorkBook = XLSX.utils.book_new();
      wb.Props = {
        Title: "Serviços",
        Subject: "Serviços",
      };

      wb.SheetNames.push("Servicos");

      const ws = XLSX.utils.json_to_sheet(
        servicosToXLSX.length == 0
          ? [
              {
                "Serviço": "",
                "Código Categoria (1 = Agregados / 2 = Alinhamento / 3 = Pneus / 4 = Baterias / 5 = Palhetas / 6 = Acessórios / 7 = Estética / 8 = Funilaria / 9 = Balanceamento)": "",
                "Código Imagem/Video/Descricao": "",
                "Tipo (2 = Produtos / 3 = Serviços)": "",
                "Fornecedor (1 = HML / 2 = Outros / 3 = Originais)": "",
                "Contabiliza Ticket Médio (1 = Sim / 2 = Não)": "",
                "Situação (1 = Habilitado / 0 = Desabilitado)": "",
                "Valor Compra": "",
                "Valor Peça": "",
                "Valor Mão de Obra": "",
                "valor Hora Mão de Obra" : "",
                "Tempo Mão de Obra": "",
                "Valor Total": "",
                "Valor Premiação": "",
                "Código Peça": "",
                "Código MO": "",
                "Código Forrnecedor": "",
                "Unidades por Caixa": "",
                "Estoque Atual": "",
                "Data Estoque": "",
              },
            ]
          : servicosToXLSX
      );
      const wscols = [
        { wch: 80 },
        { wch: 135 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
      ];
      ws["!cols"] = wscols;

      ws["A1"].s = {
        fill: {
          fgColor: { rgb: "b7b7b7" },
        },
      };
      ws["B1"].s = {
        fill: {
          fgColor: { rgb: "b7b7b7" },
        },
      };
      ws["C1"].s = {
        fill: {
          fgColor: { rgb: "b7b7b7" },
        },
      };
      ws["D1"].s = {
        fill: {
          fgColor: { rgb: "b7b7b7" },
        },
      };
      ws["E1"].s = {
        fill: {
          fgColor: { rgb: "b7b7b7" },
        },
      };
      ws["F1"].s = {
        fill: {
          fgColor: { rgb: "b7b7b7" },
        },
      };
      ws["G1"].s = {
        fill: {
          fgColor: { rgb: "b7b7b7" },
        },
      };
      ws["H1"].s = {
        fill: {
          fgColor: { rgb: "20a5e8" },
        },
      };
      ws["I1"].s = {
        fill: {
          fgColor: { rgb: "20a5e8" },
        },
      };
      ws["I1"].s = {
        fill: {
          fgColor: { rgb: "20a5e8" },
        },
      };
      for (let i = 1; i <= servicosToXLSX.length; i++) {
        let aux = i + 1;
        ws["H" + aux].z = "0.00";
        ws["I" + aux].z = "0.00";
        ws["J" + aux].z = "0.00";
        ws["K" + aux].z = "0.00";
        ws["L" + aux].z = "0.00";
        ws["M" + aux].z = "0.00";
        ws["N" + aux].z = "0.00";
        ws["T" + aux].z = 'dd/mm/yyyy';
      }
      ws["J1"].s = {
        fill: {
          fgColor: { rgb: "20a5e8" },
        },
      };
      ws["K1"].s = {
        fill: {
          fgColor: { rgb: "20a5e8" },
        },
      };
      ws["L1"].s = {
        fill: {
          fgColor: { rgb: "20a5e8" },
        },
      };
      ws["M1"].s = {
        fill: {
          fgColor: { rgb: "20a5e8" },
        },
      };
      ws["N1"].s = {
        fill: {
          fgColor: { rgb: "20a5e8" },
        },
      };
      ws["O1"].s = {
        fill: {
          fgColor: { rgb: "f5ac55" },
        },
      };
      ws["P1"].s = {
        fill: {
          fgColor: { rgb: "f5ac55" },
        },
      };
      ws["Q1"].s = {
        fill: {
          fgColor: { rgb: "f5ac55" },
        },
      };
      ws["R1"].s = {
        fill: {
          fgColor: { rgb: "28b761" },
        },
      };
      ws["S1"].s = {
        fill: {
          fgColor: { rgb: "28b761" },
        },
      };
      ws["T1"].s = {
        fill: {
          fgColor: { rgb: "28b761" },
        },
      };

      wb.Sheets["Servicos"] = ws;

      function s2ab(s) {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf); //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
        return buf;
      }

      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      blob.value = new Blob([s2ab(wbout)], {
        type: "text/plain;charset=UTF-8",
      });
    }

    function exportarXLSX() {
      const nomeConcessionaria = store.getters.concessionariaSelectedInfo.nomeFantasia.replaceAll(' ', '_');
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob.value);
        link.setAttribute("href", url);
        link.setAttribute("download", "EXPORTACAO_SERVICO_" + nomeConcessionaria.toUpperCase() + ".xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }

    const updateService = () => {
      listaServicos()
    }

    return {
      animate,
      goTo,
      goToParams,
      ptBr,
      ...toRefs(data),
      loadingTable,
      servicosHabilidados,
      trataInfoHabilitados,
      tradaInfoDesabilidado,
      fastFilter,
      listaServicos,
      filterDataHabilitado,
      setServicoEspecifico,
      servicoEspecifico,
      codConcessionaria,
      updateService,
      activeName,
      classFornecedor,
      handleClickTab,
      inputDisableOrEnable,
      servicosDesabilitado,
      servicos,
      activeTab,
      trataInfo,
      downloadExel,
    };
  },
});
