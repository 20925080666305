
import {ref, watch} from "vue";
import {ContentLoader} from 'vue-content-loader';
import store from "@/store";
import {servicoView} from "@/services/CrudService";

export default {
  name: "visualizarServicoModal",

  props: {
    servicos: {
      type: Object,
      required: true,
    }
  },

  components: {
    ContentLoader
  },

  setup(props) {
    let animate = ref(true);

    const distribuidor = store.getters.layoutConfig("distribuidor.name_empresa");

    watch(() => props.servicos, () => {
      ListaView(props.servicos?.codServico);
    })

    const servicosModal: any = ref({});

    async function ListaView(codServico) {
      animate.value = true;

      let response = await servicoView(codServico);

      servicosModal.value = response;

      if (servicosModal.value.tipo == 2) {
        servicosModal.value.tipo = 'Produtos / Peça';
      } else if (servicosModal.value.tipo == 3) {
        servicosModal.value.tipo = 'Serviços';
      } else {
        servicosModal.value.tipo = 'Outro';
      }

      if (servicosModal.value.fornecedor == 1) {
        servicosModal.value.fornecedor = distribuidor;
      } else if (servicosModal.value.fornecedor == 2) {
        servicosModal.value.fornecedor = 'Outros';
      } else {
        servicosModal.value.fornecedor = 'Originais';
      }

      animate.value = false;
    }

    return {
      animate,
      ListaView,
      servicosModal
    }
  }
}
